<template>
  <div>
    <b-row>
      <b-col>
        <div data-app>
          <v-data-table
            :headers="headers"
            :items="keys"
            item-key="name"
            class="elevation-1"
            :search="search"
            :custom-filter="filterOnlyCapsText"
          >
            <template v-slot:top>
              <b-row class="mt-5 mb-3">
                <b-col sm="6" md="6">
                  <BaseButton
                    :squared="true"
                    variant="outline-success"
                    name="Create SSH Key"
                    v-b-modal.modal-ssh
                  />
                </b-col>
                <b-col sm="6" md="6">
                  <b-input v-model="search" placeholder="Search"></b-input>
                </b-col>
              </b-row>
            </template>

            <template v-slot:item.controls="props">
              <BaseButton
                :squared="true"
                variant="outline-danger"
                name="Remove"
              />
            </template>
          </v-data-table>
        </div>
      </b-col>
    </b-row>

    <BaseModal
      id="modal-ssh"
      modalTitle="Tambah Key - Pair"
      okTitle="Simpan"
      cancelTitle="Batal"
      :centered="true"
      size="md"
      @ok="addKey"
    >
      <template v-slot:modal-content>
        <b-form>
          <BaseInput label="Nama key - pair" type="text" v-model="keyname" />
        </b-form>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import BaseButton from "@/views/pages/product/lightsail/components/BaseButton.vue";
import BaseInput from "@/views/pages/product/lightsail/components/BaseInput.vue";
import BaseModal from "@/views/pages/product/lightsail/components/BaseModal.vue";

export default {
  components: {
    BaseButton,
    BaseInput,
    BaseModal
  },
  data() {
    return {
      search: "",
      keyname: "Ini keyname",
      keys: [
        {
          no: 1,
          name: "test-key-ssh-1",
          date: "29 Januari 2021"
        },
        {
          no: 2,
          name: "test-key-ssh-2",
          date: "29 Maret 2021"
        },
        {
          no: 3,
          name: "test-key-ssh-3",
          date: "19 Januari 2021"
        },
        {
          no: 4,
          name: "test-key-ssh-4",
          date: "13 April 2021"
        },
        {
          no: 5,
          name: "test-key-ssh-5",
          date: "26 Februari 2021"
        },
        {
          no: 6,
          name: "test-key-ssh-6",
          date: "09 Mei 2021"
        }
      ]
    };
  },
  computed: {
    headers() {
      return [
        { text: "No.", value: "no" },
        { text: "Nama Key", value: "name" },
        { text: "Tanggal Dibuat", value: "date" },
        { text: "", value: "controls", sortable: false, align: "right" }
      ];
    }
  },
  methods: {
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().indexOf(search) !== -1
      );
    },
    addKey() {
      console.log(this.keyname);
    }
  }
};
</script>

<style lang="scss" scoped></style>
